<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleUpdatePWDialog" max-width="800px">
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("user") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        outlined
                        type="password"
                        :label="$t('password')"
                        v-model="user.password"
                        :rules="[rules.required, rules.min_8]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        outlined
                        type="password"
                        :label="$t('c_password')"
                        v-model="user.c_password"
                        :rules="[
                          rules.required,
                          rules.same_as_password(user.password),
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(user)"
              :disabled="!is_valid"
              :loading="is_loading_save"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    isVisibleUpdatePWDialog: {
      get() {
        return this.$store.state.users.isVisibleUpdatePWDialog;
      },
      set(value) {
        this.$store.commit("users/IS_VISIBLE_UPDATE_PW_DIALOG", value);
      },
    },
    user: {
      get() {
        return this.$store.state.users.editedUser;
      },
      set(value) {
        this.$store.commit("users/EDITED_USER", value);
      },
    },
  },

  data() {
    return {
      is_valid: true,
      is_loading_save: false,
    };
  },
  methods: {
    closeForm() {
      this.$store.dispatch("users/closeForm");
      this.$refs.form.reset();
      this.is_loading_save = false;
    },

    async saveForm(user) {
      if (this.$refs.form.validate()) {
        try {
          this.is_loading_save = true;
          await this.$store.dispatch(
            "users/updatePW",
            Object.assign(
              {},
              {
                user_id: user.id,
                new_password: user.password,
                confirm_password: user.c_password,
              }
            )
          );
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );

          this.is_loading_save = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_loading_save = false;
        }
        this.is_loading_save = false;
      }
    },
  },
};
</script>
